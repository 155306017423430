/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useContext,
  useEffect,
  useState,
  useRef,
  useReducer,
  useMemo,
  useCallback,
} from 'react'
import {
  AgxRow,
  AgxColumn,
  AgxButton,
  AgxBodyText,
  AgxLabel,
  Images,
  AgxSwitch,
  FormType,
  AgxToast,
  filterPageHideCondition,
  filterDisplayElementHideCondition,
  DocumentTypes,
  Form,
  CampaignStage,
  FormPage,
  useDeepMemo,
  AustralianState,
  RenderAnimation,
  RenderAnimationType,
  AgxMultiOptionButton,
  DocumentTypesMap,
  AgxIconCircularGreenTick,
  AgxIconCircularIncomplete,
  AgxIconCircularWarning,
  CampaignDetailModel,
  FeatureToggles,
  cleanFullAddress,
} from '@urbanx/agx-ui-components'
import { ContentLayout, PageLayout } from 'layout'
import slugify from 'slugify'
import { AgencyContext, NotificationContext, UserContext } from 'contexts'
import { useParams } from 'react-router-dom'
import { MenuTitles } from 'constants/menu'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  GetAllAgencies,
  GetCampaignById,
  SaveForm,
  SubmitForm,
  GetFormConfig,
  StaffGetUploadedFileLink,
} from 'services'
import './ManageCampaign.scss'
import { useAzureAuth } from 'hooks/useAzureAuth'
import elementRenderer from 'utils/elementRenderer'
import { FormValueType } from 'types/formConfig'
import blankContractReducer, { ActionType } from './blankContractReducer'
import { AgxToastState } from 'types/commonTypes'
import { Agency } from 'types/agency'
import isEqual from 'lodash.isequal'
import ErrorModal from 'helpers/ErrorModal'
import { NotificationType, PdfProcessedEvent } from 'types/notification'
import SetBlankContractConfirmModal from './SetBlankContractConfirmModal'
import { FormPrompt } from 'components/FormPrompt'
import { getEnumValue } from 'helpers/enumHelper'
import GetFeatureToggles from 'services/functions/campaigns/getFeatureToggles'

interface Props {
  formType: FormType
}

const ManageCampaign = (props: Props) => {
  const { formType } = props
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [isInitialRender, setIsInitialRender] = useState(true)
  const queryClient = useQueryClient()
  const stickyHeaderRow = useRef<HTMLDivElement>(null)
  const stickyLeftMenu = useRef<HTMLDivElement>(null)
  const panelContainer = useRef<HTMLDivElement>(null)
  const user = useContext(UserContext)
  const { agencyId, campaignId } = useParams()
  const [selectedAgency, setSelectedAgency] = useContext(AgencyContext)
  const notifications = useContext(NotificationContext)
  const [, getAuthToken] = useAzureAuth()
  const [validate, setValidate] = useState(false)
  const [switching, setSwitching] = useState(false)
  const [campaign, setCampaign] = useState<CampaignDetailModel | undefined>()
  const [errorContainerNames, setErrorContainerNames] = useState<string[]>([])
  const [notifyAgent, setNotifyAgent] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [isGeneratingBlankContract, setIsGeneratingBlankContract] =
    useState<boolean>(false)
  const [blankContractLink, setBlankContractLink] = useState<string | null>(
    null
  )
  const [blankContractFlattenedLink, setBlankContractFlattenedLink] = useState<
    string | null
  >(null)
  const [submitFormErrors, setSubmitFormErrors] = useState<string[]>([])
  const stickyHeaderOffsetTop = 302
  const [formValues, dispatch] = useReducer(blankContractReducer, {})
  const state = campaign?.address?.state
    ? getEnumValue(AustralianState, campaign.address.state)
    : undefined

  const showQldInfo = state === AustralianState.QLD
  const isNSW = state === AustralianState.NSW
  const campaignIsValid = useMemo<boolean>(
    () =>
      campaign?.stage
        ? [
            CampaignStage.AgreementSignedByAllParties,
            CampaignStage.ListingCurrent,
            CampaignStage.ListingUnderContract,
          ].includes(getEnumValue(CampaignStage, campaign.stage))
        : false,
    [campaign?.stage]
  )

  const agenciesQueryKey = ['all-agencies']
  const campaignsQueryKey = [`campaigns-${agencyId}`, agencyId]
  const formConfigQueryKey = [
    `formConfig-${campaignId}`,
    formType,
    state,
    campaignId,
    undefined,
  ]

  const { data: formConfig, isLoading: isLoadingFormConfig } = useQuery<
    Form | undefined
  >({
    queryKey: formConfigQueryKey,
    queryFn: (queryKey) => GetFormConfig(queryKey, getAuthToken),
  })

  const { data: featureToggles } = useQuery<FeatureToggles | undefined>({
    queryKey: [`get-featureToggles`],
    queryFn: (queryKey) => GetFeatureToggles(getAuthToken),
  })

  // Prepopulate form values with existing data
  useEffect(() => {
    if (!isLoadingFormConfig) {
      dispatch({
        type: ActionType.INIT,
        payload: formConfig?.existingData ?? formValues,
      })
    }
  }, [isLoadingFormConfig, campaignId, formConfig?.existingData])

  const [toastState, updateToastState] = useState<AgxToastState>({
    color: 'success',
    message: '',
    open: false,
  })

  const { mutate: saveFormValues } = useMutation(SaveForm, {
    onSuccess: () => {
      fetchCampaign(campaignId)
      setIsFormDirty(false)
      setNotifyAgent(false)

      Promise.all([
        queryClient.invalidateQueries({
          queryKey: formConfigQueryKey,
        }),
        queryClient.invalidateQueries({
          queryKey: campaignsQueryKey,
        }),
        validatePage(),
      ])

      if (switching) {
        updateToastState({
          color: 'success',
          message: 'Contract Offline',
          open: true,
        })
        if (isNSW) {
          Promise.all([
            queryClient.invalidateQueries({
              queryKey: formConfigQueryKey,
            }),
            queryClient.invalidateQueries({
              queryKey: campaignsQueryKey,
            }),
          ])
        }
      } else {
        updateToastState({
          color: 'success',
          message: 'Contract saved successfully',
          open: true,
        })
      }
      setSwitching(false)
    },
    onError: () => {
      updateToastState({
        color: 'error',
        message: 'Error saving campaign',
        open: true,
      })
    },
  })

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms))

  const validatePage = async () => {
    setValidate(true)
    await delay(100)

    const errorElements = document.getElementsByClassName('error')
    const errorContainers: string[] = []
    pages.forEach((page) => {
      const container = document.getElementById(slugify(page.name))
      if (container) {
        const errorInContainers = container.getElementsByClassName('error')
        if (errorInContainers && errorInContainers.length > 0) {
          errorContainers.push(page.name)
        }
        const actionAlertsInContainers = container.getElementsByClassName(
          'actionAlertContainer'
        )
        const infoAlertsInContainers = container.getElementsByClassName(
          'informationAlertContainer'
        )
        if (
          (actionAlertsInContainers && actionAlertsInContainers.length > 0) ||
          (infoAlertsInContainers && infoAlertsInContainers.length > 0)
        ) {
          errorContainers.push(page.name)
        }
      }
    })
    setErrorContainerNames(errorContainers)
    return errorElements && errorElements.length > 0 ? false : true
  }

  const onSaveFormValues = async () => {
    if (!campaign || !state) return

    if (campaignIsValid) {
      saveFormValues({
        CampaignId: campaignId || '',
        FormType: formType,
        State: state,
        BlankContractIsLive: campaign?.blankContractIsLive,
        FormData: formValues,
        PageStatuses: [],
        getAuthToken,
      })
    } else {
      updateToastState({
        color: 'error',
        message: 'Campaign is not ready for blank contract generation',
        open: true,
      })
    }
  }

  const { mutate: submitFormValues } = useMutation(SubmitForm, {
    onSuccess: async (data) => {
      fetchCampaign(campaignId)
      if (isNSW) {
        notificationsRegisteredEvent()
      }
      await validatePage()
      if (data?.errors?.length ?? 0 > 0) {
        setSubmitFormErrors(data?.errors ?? [])
        setOpenErrorModal(true)
        setIsGeneratingBlankContract(false)
        setNotifyAgent(false)
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: formConfigQueryKey,
          }),
          queryClient.invalidateQueries({
            queryKey: campaignsQueryKey,
          }),
        ])
      } else {
        setIsFormDirty(false)
        if (!openErrorModal) {
          updateToastState({
            color: 'success',
            message: 'Contract Live',
            open: true,
          })
        }
      }

      if (!switching) {
        updateToastState({
          color: 'success',
          message: 'Contract saved successfully',
          open: true,
        })
      }
      setSwitching(false)
    },
    onError: () => {
      updateToastState({
        color: 'error',
        message: 'Error generating blank contract',
        open: true,
      })
    },
  })

  const toggleLiveBlankContract = async (isSwitchLive: boolean) => {
    if (!campaignIsValid || isLoadingFormConfig) return
    if (isSwitchLive) {
      setOpenConfirmationModal(true)
    } else {
      onSubmit()
    }
  }

  const onSubmit = () => {
    if (!campaignIsValid || isLoadingFormConfig || !state) return

    setIsGeneratingBlankContract(true)
    submitFormValues({
      CampaignId: campaignId || '',
      FormType: formType,
      State: state,
      BlankContractIsLive: !campaign?.blankContractIsLive,
      NotifyAgent: notifyAgent,
      FormData: formValues,
      getAuthToken,
    })

    notificationsRegisteredEvent()
  }

  const { data: agencies } = useQuery<Agency[] | undefined>({
    queryKey: agenciesQueryKey,
    queryFn: () => GetAllAgencies(getAuthToken),
  })

  const fetchCampaign = async (campaignId: string | undefined) => {
    if (!campaignId) return
    const currentCampaign = await GetCampaignById(campaignId, getAuthToken)
    if (!currentCampaign) return
    setCampaign(currentCampaign)
  }

  useEffect(() => {
    fetchCampaign(campaignId)
  }, [campaignId])

  const onChange = (change: {
    id: string
    value: FormValueType[keyof FormValueType]
  }) => {
    const { id, value } = change

    if (
      id === 'CustomSpecialConditions' ||
      (formValues && !isEqual(formValues[id], change.value))
    ) {
      if (!isInitialRender) {
        setIsFormDirty(true)
      }
      dispatch({ type: ActionType.UPDATE, payload: value, key: id })
    }
  }

  const pages = useDeepMemo<FormPage[]>(() => {
    return filterPageHideCondition(formConfig?.pages ?? [], formValues)
  }, [campaignId, formValues, formConfig?.pages])

  useEffect(() => {
    if (agencyId && selectedAgency?.id !== agencyId)
      setSelectedAgency(agencies?.find((a) => a.id === agencyId))
  }, [agencyId, setSelectedAgency, agencies])

  useEffect(() => {
    if (campaign?.blankSalesContract) {
      setBlankContractLink(campaign.blankSalesContract.containerFilePath)
    }
    if (campaign?.blankSalesContractFlattened) {
      setBlankContractFlattenedLink(
        campaign.blankSalesContractFlattened.containerFilePath
      )
    }
  }, [campaign?.blankSalesContract])

  useEffect(() => {
    const handleScroll = () => {
      if (stickyHeaderRow.current) {
        if (window.scrollY > stickyHeaderOffsetTop) {
          ;(stickyHeaderRow.current as HTMLDivElement).classList.add('sticky')

          if (stickyLeftMenu.current)
            (stickyLeftMenu.current as HTMLDivElement).classList.add(
              'stickyMenu'
            )
          if (panelContainer.current) {
            ;(panelContainer.current as HTMLDivElement).classList.add(
              'addMarginToPanelContainer'
            )
          }
        } else {
          ;(stickyHeaderRow.current as HTMLDivElement).classList.remove(
            'sticky'
          )

          if (stickyLeftMenu.current)
            (stickyLeftMenu.current as HTMLDivElement).classList.remove(
              'stickyMenu'
            )

          if (panelContainer.current) {
            ;(panelContainer.current as HTMLDivElement).classList.remove(
              'addMarginToPanelContainer'
            )
          }
        }
      }
    }
    if (showQldInfo) {
      window.addEventListener('scroll', handleScroll)
    }
    setTimeout(() => {
      setIsInitialRender(false)
    }, 1000)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [showQldInfo])

  const notificationsRegisteredEvent = () => {
    if (isNSW) {
      if (isGeneratingBlankContract) {
        setIsGeneratingBlankContract(false)
        setIsFormDirty(false)
        setNotifyAgent(false)
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: formConfigQueryKey,
          }),
          queryClient.invalidateQueries({
            queryKey: campaignsQueryKey,
          }),
        ])
        updateToastState({
          color: 'success',
          message: 'Blank contract saved',
          open: true,
        })
        return
      }
    } else {
      if (
        notifications &&
        notifications?.registerEvent !== null &&
        notifications?.unregisterEvent !== null
      ) {
        return notifications.registerEvent(
          NotificationType.PdfProcessedEvent,
          (event: PdfProcessedEvent) => {
            if (
              event.campaignId === campaignId &&
              (event.uploadedDocument.documentType ===
                DocumentTypes.BlankSalesContract ||
                event.uploadedDocument.documentType ===
                  DocumentTypes.BlankSalesContractFlattened)
            ) {
              Promise.all([
                queryClient.invalidateQueries({
                  queryKey: formConfigQueryKey,
                }),
                queryClient.invalidateQueries({
                  queryKey: campaignsQueryKey,
                }),
              ])

              setIsGeneratingBlankContract(false)

              if (
                event.uploadedDocument.documentType ===
                DocumentTypes.BlankSalesContractFlattened
              ) {
                setBlankContractFlattenedLink(
                  event.uploadedDocument.containerFilePath
                )
              } else if (
                event.uploadedDocument.documentType ===
                DocumentTypes.BlankSalesContract
              ) {
                updateToastState({
                  color: 'success',
                  message: 'Blank contract generated',
                  open: true,
                })
                setBlankContractLink(event.uploadedDocument.containerFilePath)
              }

              setIsFormDirty(false)
              setNotifyAgent(false)
            }
          }
        )
      }
    }
  }

  useEffect(() => {
    if (
      notifications &&
      notifications?.registerEvent !== null &&
      notifications?.unregisterEvent !== null
    ) {
      notificationsRegisteredEvent()

      return () => {
        if (notifications && notifications?.unregisterEvent !== null) {
          notifications.unregisterEvent(NotificationType.PdfProcessedEvent)
        }
      }
    }
  }, [notifications?.registerEvent, notifications?.unregisterEvent])

  useEffect(() => {
    validatePage()
  }, [formValues, pages])

  const fileDownloadOptions = useMemo(() => {
    if (!blankContractLink) return []
    const options = []

    if (blankContractLink) {
      options.push({
        text: DocumentTypesMap[DocumentTypes.BlankSalesContract],
        onClick: async () => {
          await downloadDocument(blankContractLink)
        },
      })
    }
    if (blankContractFlattenedLink) {
      options.push({
        text: DocumentTypesMap[DocumentTypes.BlankSalesContractFlattened],
        onClick: async () => {
          await downloadDocument(blankContractFlattenedLink)
        },
      })
    }

    return options
  }, [blankContractLink, blankContractFlattenedLink])

  const downloadDocument = async (link: string) => {
    const documentLink = await StaffGetUploadedFileLink(
      {
        queryKey: [`document-link-${link}`, link],
      },
      getAuthToken
    )

    if (!documentLink) {
      console.error('Error getting the file link')
      return
    }

    //Force download instead of opening the tab
    const anchor = document.createElement('a')
    anchor.id = 'pdfAnchor'
    anchor.href = documentLink
    anchor.download = 'true'
    anchor.target = '_blank'
    anchor.textContent = ''
    document.body.appendChild(anchor)
    anchor.click()
    setTimeout(function () {
      const pdfAnchor = document.getElementById('pdfAnchor')
      if (pdfAnchor) {
        document.body.removeChild(pdfAnchor)
      }
    }, 200)
  }

  const onConfirmSetBlankContract = () => {
    setSwitching(true)
    onSubmit()
  }

  const onNotifyAgentToggle = (checked: boolean) => {
    setNotifyAgent(checked)
  }

  const renderTick = useCallback(
    (name: string) => {
      return (
        <div className="tickBox">
          {errorContainerNames.includes(name) ? (
            <AgxIconCircularWarning width={13} height={14} />
          ) : (
            <AgxIconCircularGreenTick width={13} height={14} />
          )}
        </div>
      )
    },
    [errorContainerNames]
  )

  const renderCompletionStatus = useCallback(() => {
    if (campaign?.address.state == 'QLD' && campaignIsValid) {
      const completePanelsNum = pages.filter(
        (page) => !errorContainerNames.includes(page.name)
      ).length
      const totalPanelsNum = pages.length
      const allPanelsComplete = completePanelsNum === totalPanelsNum
      return (
        <>
          <div className="circularProgress">
            {allPanelsComplete ? (
              <AgxIconCircularGreenTick width={26} height={27} />
            ) : (
              <AgxIconCircularIncomplete width={26} height={27} />
            )}
          </div>
          <AgxColumn>
            <AgxBodyText extraClasses="label">Blank Contract</AgxBodyText>
            {allPanelsComplete ? (
              <AgxBodyText extraClasses="completionStatus">
                <span className="completionStatus--complete">Completed</span>
              </AgxBodyText>
            ) : (
              <AgxBodyText extraClasses="completionStatus">
                <span className="completionStatus--complete">
                  {completePanelsNum}
                </span>
                <span className="completionStatus--incomplete">
                  /{totalPanelsNum} completed
                </span>
              </AgxBodyText>
            )}
          </AgxColumn>
        </>
      )
    }
    return <AgxBodyText large>Blank Contract</AgxBodyText>
  }, [pages, errorContainerNames])

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName={selectedAgency?.name || ''}
      currentPageTitle={`Campaigns / ${cleanFullAddress(campaign?.address)}`}
      isBlankContract={true}
      selectedCampaign={campaign}
    >
      <FormPrompt hasUnsavedChanges={isFormDirty} />
      <AgxToast selector="#agxToast" toastState={toastState} />
      <ContentLayout
        hasSideMenu={true}
        activeMenu={MenuTitles.BLANKCONTRACT}
        isBlankContract={true}
      >
        <AgxColumn veryLargeGap>
          {isGeneratingBlankContract && (
            <div className="loadingAnimation">
              <div className="loadingAnimationInner">
                <RenderAnimation icon={RenderAnimationType.Settings} />
              </div>
            </div>
          )}

          <div ref={stickyHeaderRow}>
            <AgxRow
              spaceBetween
              extraClasses={`borderBottomContainer ${
                isGeneratingBlankContract ? 'disabledDiv' : ''
              }`}
            >
              <AgxRow largeGap>
                {renderCompletionStatus()}
                <AgxSwitch
                  dataTestId="liveSwitch"
                  id={'liveSwitch'}
                  onChange={toggleLiveBlankContract}
                  width={87}
                  dataOff="Offline"
                  dataOn="Live"
                  isChecked={campaign?.blankContractIsLive}
                  parentControlValue={true}
                />
              </AgxRow>
              <AgxRow largeGap>
                {showQldInfo && (
                  <AgxMultiOptionButton
                    dataTestId="btn-downloadContract"
                    id="downloadContract"
                    text="Download Contract"
                    medium
                    hollow
                    disabled={
                      !blankContractLink ||
                      !campaignIsValid ||
                      isGeneratingBlankContract ||
                      isLoadingFormConfig
                    }
                    rightIcon={<Images.Download />}
                    options={fileDownloadOptions}
                    onClick={
                      fileDownloadOptions?.length === 1
                        ? () => fileDownloadOptions[0].onClick
                        : undefined
                    }
                  />
                )}
                <AgxButton
                  text="Save Changes"
                  medium
                  primary
                  disabled={
                    !campaignIsValid ||
                    isGeneratingBlankContract ||
                    isLoadingFormConfig
                  }
                  onClick={onSaveFormValues}
                  dataTestId="btn-saveChanges"
                />
              </AgxRow>
            </AgxRow>
          </div>
          <AgxRow extraClasses={!showQldInfo ? 'column' : ''}>
            {campaignIsValid && showQldInfo ? (
              <div ref={stickyLeftMenu}>
                <AgxColumn largeGap>
                  {pages?.map((panel) => (
                    <div key={slugify(panel.name)} className="leftMenu">
                      {renderTick(panel.name)}
                      <a
                        href={`#${slugify(panel.name)}`}
                        className="leftMenuLinkStyle"
                      >
                        <AgxBodyText small extraClasses="strong">
                          {panel.name}
                        </AgxBodyText>
                      </a>
                    </div>
                  ))}
                </AgxColumn>
              </div>
            ) : (
              <></>
            )}
            <div id="panelContainer" ref={panelContainer}>
              {!campaignIsValid ? (
                <AgxLabel extraClasses="loadingText_campaigns">
                  This campaign is not ready for blank contract generation
                </AgxLabel>
              ) : !isLoadingFormConfig && campaign ? (
                <AgxColumn extraLargeGap>
                  {pages?.map((panel) => {
                    return (
                      <div
                        key={`${slugify(panel.name)}`}
                        className={`${
                          panel.hideFormPageName ? '' : 'panelContainer'
                        }`}
                        id={slugify(panel.name)}
                      >
                        <AgxColumn extraLargeGap>
                          {!panel.hideFormPageName && (
                            <div className="panelTitleContainer">
                              <AgxBodyText medium>{panel.name}</AgxBodyText>
                            </div>
                          )}
                          {panel.elements &&
                            elementRenderer({
                              elements: filterDisplayElementHideCondition(
                                panel.elements,
                                formValues
                              ),
                              onChange,
                              campaign: campaign as CampaignDetailModel,
                              getAuthToken,
                              formValues,
                              validate,
                              featureToggles,
                            })}
                        </AgxColumn>
                      </div>
                    )
                  })}
                </AgxColumn>
              ) : (
                <></>
              )}
            </div>
          </AgxRow>
        </AgxColumn>

        {openErrorModal ? (
          <ErrorModal
            errorList={submitFormErrors}
            setShowModal={setOpenErrorModal}
          />
        ) : (
          <></>
        )}

        {openConfirmationModal ? (
          <SetBlankContractConfirmModal
            setShowModal={setOpenConfirmationModal}
            onConfirm={onConfirmSetBlankContract}
            onNotifyAgentToggle={onNotifyAgentToggle}
          />
        ) : (
          <></>
        )}
      </ContentLayout>
    </PageLayout>
  )
}

export default ManageCampaign
