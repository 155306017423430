import { useMemo } from 'react'
import {
  AgxSelect,
  AgxMultiSelect,
  AgxColumn,
  AgxLabel,
  AgxRow,
} from '@urbanx/agx-ui-components'
import isEqual from 'lodash/isEqual'
import { Agent, Permission } from '../../../../types/agency'

interface Props {
  agents: Agent[]
  permission: Permission
  setPermission: (
    updatePermission: (previousState: Permission | undefined) => Permission
  ) => void
}

const AgentsSelector = (props: Props) => {
  const { agents, permission, setPermission } = props

  const agentOptions = agents.map((agent) => {
    return {
      value: agent.id,
      label: `${agent.name?.firstName} ${agent.name?.lastName}`,
    }
  })

  const userAccessAgents = agents.map((agent) => ({
    value: agent.id,
    label: `${agent.name?.firstName} ${agent.name?.lastName}`,
  }))

  const leadAgentOptions = useMemo(
    () =>
      [
        {
          value: '',
          label: 'Please select an agent',
        },
      ].concat(
        agentOptions.filter((agent) => agent.value !== permission.secondAgentId)
      ),
    [permission.secondAgentId]
  )

  const secondAgentOptions = useMemo(
    () =>
      [
        {
          value: '',
          label: 'Please select an agent',
        },
      ].concat(
        agentOptions.filter((agent) => agent.value !== permission.leadAgentId)
      ),
    [permission.leadAgentId]
  )

  const leadAgentDefaultValue = useMemo(() => {
    return leadAgentOptions.find(
      (agent) => agent.value === permission.leadAgentId
    )
  }, [permission.leadAgentId])

  const secondAgentDefaultValue = useMemo(() => {
    return secondAgentOptions.find(
      (agent) => agent.value === permission.secondAgentId
    )
  }, [permission.secondAgentId])

  const createdByUser = agents.find(
    (agent) => agent.id === permission.createdByUserId
  )

  if (!permission) return null

  return (
    <AgxColumn extraLargeGap extraClasses={'panelTitleContainer'}>
      <AgxRow>
        <AgxLabel medium>Created By:&nbsp;&nbsp;</AgxLabel>
        <AgxLabel medium>{`${createdByUser?.name?.firstName ?? ''} ${
          createdByUser?.name?.lastName ?? ''
        }`}</AgxLabel>
      </AgxRow>
      <AgxSelect
        id={`leadAgent`}
        label="Lead Agent"
        options={leadAgentOptions}
        onValueChanged={({ value }) =>
          setPermission((previousState) => ({
            ...previousState,
            leadAgentId: value,
          }))
        }
        required
        defaultValue={leadAgentDefaultValue}
      />
      <AgxSelect
        id={`secondAgent`}
        label="Second Agent"
        options={secondAgentOptions}
        onValueChanged={({ value }) =>
          setPermission((previousState) => ({
            ...previousState,
            secondAgentId: value,
          }))
        }
        defaultValue={secondAgentDefaultValue}
      />

      <AgxMultiSelect
        id={`userAccess`}
        label="Additional User Access"
        hideOptionalLabel
        defaultValue={permission.assignedAgentIds ?? []}
        onValueChanged={({ value }: { value: string[] }) => {
          if (!isEqual(value, permission.assignedAgentIds)) {
            setPermission((previousState) => ({
              ...previousState,
              assignedAgentIds: value,
            }))
          }
        }}
        options={userAccessAgents}
      />
    </AgxColumn>
  )
}

export default AgentsSelector
