export enum StaffAction {
  SELF_EDIT = 'self_edit',
  EDIT = 'edit',
  VIEW = 'view',
  CREATE = 'create',
}

export interface IStaff {
  id: string
  firstName: string
  lastName: string
  title: string
  email: string
  phoneNumber: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  photo?: any
  role: StaffRole | null
  emailInvitationSent: boolean
}

export class Staff implements IStaff {
  id: string
  firstName: string
  lastName: string
  title: string
  email: string
  phoneNumber: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadedDocument?: any
  role: StaffRole | null
  emailInvitationSent: boolean
  constructor() {
    this.id = ''
    this.firstName = ''
    this.lastName = ''
    this.title = ''
    this.email = ''
    this.phoneNumber = ''
    this.uploadedDocument = undefined
    this.role = null
    this.emailInvitationSent = false
  }
  photo?: any
}

export enum StaffRole {
  BusinessManager = 'businessmanager',
  ListingsAdmin = 'listingsadmin',
  SalesAdmin = 'salesadmin',
  MarketingExpert = 'marketingexpert',
  SuperAdmin = 'superadmin',
  Executive = 'executive',
}

export interface IAgencyUrbanXTeam {
  businessManager?: Staff
  listingsAdmin?: Staff
  salesAdmin?: Staff
  marketingExpert?: Staff
}

export class AgencyUrbanXTeam implements IAgencyUrbanXTeam {
  businessManager?: Staff
  listingsAdmin?: Staff
  salesAdmin?: Staff
  marketingExpert?: Staff
  constructor() {
    this.businessManager = new Staff()
    this.listingsAdmin = new Staff()
    this.salesAdmin = new Staff()
    this.marketingExpert = new Staff()
  }
}

export class AgencyUrbanXTeamRequest {
  readonly BusinessManagerId?: string | null
  readonly ListingsAdminId?: string | null
  readonly SalesAdminId?: string | null
  readonly MarketingExpertId?: string | null

  constructor(
    businessManagerId?: string | null,
    listingsAdminId?: string | null,
    salesAdminId?: string | null,
    marketingExpertId?: string | null
  ) {
    this.BusinessManagerId = businessManagerId
    this.ListingsAdminId = listingsAdminId
    this.SalesAdminId = salesAdminId
    this.MarketingExpertId = marketingExpertId
  }
}
